<template>
  <router-view :key="$route.fullPath"/>
  <!--  <div id="loader" v-if="store.state.isLoading">-->
  <!--    <LoadingLogo></LoadingLogo>-->
  <!--  </div>-->
  <!--  <Popups/>-->
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script lang="ts">

import {Options, Vue} from "vue-class-component";
import store from "@/store";
import router from "@/router";
import {helper} from "@/service/Helper";


@Options({
  methods: {
    router() {
      return router
    }
  }
})
export default class App extends Vue {

  async mounted() {
    try{
    await helper.getAlgo()
    }catch (e){}
  }

  getFingerprint() {

  }
}
</script>
