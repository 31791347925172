/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from "axios";

import store from "@/store";
import router from "@/router";
import {helper} from "@/service/Helper";
import {auth} from "@/service/Auth";

class Api {
    public axios: AxiosInstance;
    public core = process.env.VUE_APP_CORE_URL;
    public key = process.env.VUE_APP_KEY;
    public recaptcha = process.env.VUE_APP_RECAPTCHA_KEY;
    public uploadRoot = this.core + "uploads/";
    public refreshAttempt = 0;

    constructor() {
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        const baseUrl = location.origin;
        // console.log(baseUrl)
        this.axios = axios.create({
            baseURL: "/",
            headers,
            data: {},
        });
        // this.axios.defaults.withCredentials = true
        this.addLoadingInterceptor(this.axios);
    }

    addLoadingInterceptor(client: AxiosInstance) {
        client.interceptors.request.use(
            (config: any) => {
                const now = new Date()
                config.headers.At = now.toISOString();
                if (config.noAuth) {
                    return config;
                }
                if (config.params && config.params.hasOwnProperty("noAuth")) {
                    return config;
                }
                if (config.at) {
                    config.headers.At = config.at;
                }
                if (config.params && config.params.hasOwnProperty("token")) {
                    config.headers.Authorization = `Bearer ${config.params.token}`;
                }
                if (store.state.algo) {
                    config.headers.Algo = `${store.state.algo}`;
                }

                if (store.state.userToken && store.state.userToken.length) {
                    config.headers.Authorization = `Bearer ${store.state.userToken}`;
                }
                if (config.token) {
                    config.headers.Authorization = `Bearer ${config.token}`;
                }
                return config;
            },
            (error: AxiosError) => {
                console.log("REQUEST ERROR");
                console.log(error);
                if (error.response && error.response.status === 401) {
                    console.log("access denied");
                }
            }
        );

        client.interceptors.response.use(
            (response: any) => {
                if (response.data.status && response.data.message) {
                    if (response.data.message === 'Jeton de connexion inconnu') {
                        return
                    }
                    if (response.data.status === "error") {
                        let keepAlive = false;
                        if (response.data.keepAlive) {
                            keepAlive = true
                        }
                    }
                }
                // store.commit('stopLoading');
                // Do something with response data
                return response;
            },
            async (error: any) => {
                store.commit("stopLoading");
                console.log('RESPONSE ERROR');
                console.log(error);
                if (error.response && error.response.status === 403) {
                    // new Popup("Erreur", error.response.data.message, "danger");
                    if (error.response.data.route) {
                        router.push({name: error.response.data.route.name, params: error.response.data.route.params})
                    }
                    return Promise.resolve(error);
                }
                if (error.response && error.response.status === 401) {
                    if (error.response.data.message === "Invalid credentials.") {
                        // new Popup("Identifiants Invalides", "Nom d'utilisateur ou mot de passe incorrect", "danger", "fad user-slash");
                        return Promise.reject(error);
                    }
                    if (this.refreshAttempt > 5) {
                        console.log('logout')
                        return;
                    }
                    this.refreshAttempt++;

                    const refreshSuccess = await this.refreshToken();
                    if (!refreshSuccess && this.refreshAttempt < 5) {
                        console.log("refresh failed");
                        auth.logout();

                        // Swal.fire({
                        //     title: Vue.prototype.Ktrans('Session_expired'),
                        //     text: Vue.prototype.Ktrans('Sorry_you_have_been_disconnected'),
                        //     icon: "warning",
                        //     timer: 5000
                        // });
                    } else {
                        console.log("refresh succeed");
                        // this.refreshAttempt = 0;
                        return this.axios.request(error.config);
                    }
                    return Promise.reject(error);
                }
                if (error.response && error.response.status === 500) {
                    if (error.response.data.message === 'REFRESH TOKEN') {
                        return Promise.reject(error);
                    }
                }
                if (error.response) {
                    if (error.response.data) {
                        const data = error.response.data;
                        if (data.hasOwnProperty("detail")) {
                            // new Popup("Erreur", data.detail, "danger");
                        } else if (data.hasOwnProperty("message")) {
                            // new Popup("Erreur", data.message, "danger");
                        } else {
                            // new Popup("Erreur", "Une erreur est survenue lors du chargement", "danger");
                        }
                    }
                }
                // return Promise.reject(error);
            }
        );
    }

    async get(service: string, endpoint: string, config = {}): Promise<any | null> {
        return await this.axios.get(service + endpoint, config);
    }

    async downloadPdf(endpoint: string, name?: string) {
        store.commit("loading");
        const res = await this.axios.get(endpoint, {responseType: "blob"});
        store.commit("stopLoading");
        if (res && res.data) {
            const blob = new Blob([res.data], {type: "application/pdf"});
            let url = window.URL || window.webkitURL;
            let link = url.createObjectURL(blob);
            let a = document.createElement("a");
            if (name) {
                a.setAttribute("download", name);
            }
            a.setAttribute("href", link);
            a.setAttribute("target", "_blank");

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    async downloadBlob(res: AxiosResponse) {
        if (res && res.data) {
            const blob = new Blob([res.data], {type: res.headers['content-type']});
            let url = window.URL || window.webkitURL;
            let link = url.createObjectURL(blob);
            let a = document.createElement('a');
            a.setAttribute("download", res.headers['name']);
            a.setAttribute("href", link);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

        }
    }

    async downloadFile(file: any) {
        store.commit("loading");
        const res = await this.axios.get("shared/download/file/" + file.uuid, {
            responseType: "blob",
            params: {noAuth: true},
        });
        store.commit("stopLoading");
        const blob = new Blob([res.data], {type: file.type});
        let url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("download", file.realName);
        a.setAttribute("href", link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    post(service: string, endpoint: string, data: any, config?: any): Promise<AxiosResponse> {
        data = JSON.parse(helper.simplifyEntities(data));
        return this.axios.post(service + endpoint, data, config);
    }

    put(service: string, endpoint: string, body: any, reqOpts?: any) {
        return this.axios.put(service + endpoint, body, reqOpts);
    }

    patch(service: string, endpoint: string, body: any, reqOpts?: any) {
        return this.axios.patch(service + endpoint, body, reqOpts);
    }

    delete(service: string, endpoint: string, reqOpts?: any) {
        return this.axios.delete(service + endpoint, reqOpts);
    }


    async refreshToken() {
        try {
            console.log('refresh ' + this.refreshAttempt)
            const result = await this.post(this.core, "auth/token/refresh", {refreshToken: auth.loadData().refreshToken}, {noAuth: true});
            if (result) {
                const data = result.data;
                store.state.userToken = data.token;
                store.state.userRefreshToken = data.refreshToken;
                localStorage.setItem("refreshToken", data.refreshToken);
                localStorage.setItem("userToken", data.token);
                return true;
            }
            return false;
        } catch (error: any) {
            return false;
        }
    }

    loadAuth(): any {
        let token = (store.state as any).userToken;
        if (!token) {
            token = localStorage.getItem("userToken");
            if (token === "null") {
                token = "";
            }
        }
        let refreshToken = (store.state as any).userRefreshToken;
        if (!refreshToken) {
            refreshToken = localStorage.getItem("userRefreshToken");
            if (token === "null") {
                refreshToken = "";
            }
        }
        let user = (store.state as any).user;
        if (!user) {
            user = localStorage.getItem("user");
            if (user === "null") user = null;
        }
        return {token, refreshToken, user};
    }

    isConnected(): boolean {
        const data = this.loadAuth();
        if (!data) {
            return false;
        }
        return data.token && data.refreshToken && data.user;
    }

    public window = process.env.VUE_APP_KEY;
}

export const api = new Api();
