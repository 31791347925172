/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import store from "@/store";
import router from "@/router";
import {api} from "@/service/Api";
import {helper} from "@/service/Helper";

class Auth {

    ROLE_HIERARCHY: any = {
        ROLE_ADMIN: ['ROLE_USER', 'ROLE_COMMUNICATION', 'ROLE_SELL', 'ROLE_PRODUCTION', 'ROLE_INCOME'],
        ROLE_MANAGER: ['ROLE_USER', 'ROLE_COMMUNICATION', 'ROLE_SELL', 'ROLE_PRODUCTION', 'ROLE_INCOME']
    }

    constructor() {
    }

    async login(email: string, password: string, captchaResponse = '') {
        const result = await api.post(api.core, 'auth/login?ignore=' + helper.generateId(), {
            email: email,
            password: password,
            captcha: captchaResponse
        });
        if (!result || (result && !result.data)) {
            return false;
        }
        const data = result.data;
        if (data.token) {
            this.tokenLogin(data.token, data.refreshToken, data.user)
            return true;
        }
        return false;
    }

    tokenLogin(token: string, refreshToken: string, user: any = null) {
        store.state.userToken = token
        store.state.userRefreshToken = refreshToken
        localStorage.setItem('refreshToken', refreshToken)
        localStorage.setItem('userToken', token)
        // localStorage.setItem('user', JSON.stringify(user))
        router.push({name: 'admin-category-list'})
    }

    loadData() {
        const token = localStorage.getItem('userToken') || '';
        const refreshToken = localStorage.getItem('refreshToken') || '';
        if (store) {
            store.state.userToken = token
            store.state.userRefreshToken = refreshToken
        }
        return {token, refreshToken};
    }

    isConnected(): any {
        const data = this.loadData();
        if (!data) {
            return false;
        }
        return data.token;
    }

    isAdmin(): boolean {
        const data = this.loadData();
        if (!data) {
            return false;
        }
        const payload = this.parseJwt(data.token);
        if (payload.hasOwnProperty('roles')) {
            return payload.roles.includes('ROLE_ADMIN');
        }
        this.logout();
        return false;
    }

    logout(): void {
        if (auth.isConnected()) {
            localStorage.removeItem('user');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('token');
            localStorage.clear();
        }
        if (router) {
            router.push({name: 'home'});
        }
    }

    hasRole(role: any) {
        if (Array.isArray(role)) {
            return this.hasRoles(role as any)
        }
        const {token} = this.loadData()
        const data = this.parseJwt(token)
        for (let r of data.roles) {
            if (role === r) {
                return true
            }
        }
        return data.roles.includes('ROLE_ADMIN')
    }


    getRoles() {
        const {token} = this.loadData()
        const data = this.parseJwt(token)
        return data.roles
    }

    hasRoles(roles: []): boolean {
        let full = true;
        for (let role of roles) {
            if (!this.hasRole(role)) {
                full = false
            }
        }
        return full || this.hasRole('ROLE_ADMIN')
    }

    hasOneRole(roles: []) {
        for (let role of roles) {
            if (this.hasRole(role)) {
                return true;
            }
        }
        return this.hasRole('ROLE_ADMIN')
    }

    parseJwt(token: string): any {
        if (helper.empty(token)) {
            this.logout()
            // console.log(token + ' token is empty');
            return {roles: []};
        }
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}

export const auth = new Auth();
